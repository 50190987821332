import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import AdminRoute from './AdminRoute';
import './global.css';
import Crawling_Websites from './pages/crawling_websites';
import Crawled_Jobs from './pages/crawled_jobs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <AdminRoute >
              <Dashboard />
            </AdminRoute>
          }
        />
          <Route
          path="/crawling_websites"
          element={
            <AdminRoute >
              <Crawling_Websites />
            </AdminRoute>
          }
        />
          <Route
          path="/crawled_jobs"
          element={
            <AdminRoute >
              <Crawled_Jobs />
            </AdminRoute>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
