import React from "react";
import Sidebar from "../components/Sidebar";

const Dashboard = () => {
  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar />
      <main className="w-full p-8">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {["Total Projects", "Active Campaigns", "Total Websites"].map(
              (title) => (
                <div key={title} className="bg-white p-6 rounded-lg shadow">
                  <h3 className="text-lg font-semibold mb-2">{title}</h3>
                  <p className="text-3xl font-bold">0</p>
                </div>
              )
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
