import React, { useState } from "react";
import {
  LayoutDashboard,
  Users,
  Settings,
  FileText,
  Calendar,
  Mail,
  Search,
  Bell,
  Menu,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Sidebar = ({ children }) => {
  const navigate = useNavigate();

  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const navigationItems = [
    {
      href: "/dashboard",
      icon: <LayoutDashboard />,
      text: "Dashboard",
    },
    {
      href: "/crawling_websites",
      icon: <FileText />,
      text: "Crawling Websites",
    },
    {
      href: "/crawled_jobs",
      icon: <FileText />,
      text: "Crawled Jobs",
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("tutorPlatterAdminToken");
    navigate("/login");
  };

  return (
      <aside
        className={`${
          isSidebarOpen ? "w-64 p-4" : "w-16 p-2"
        } h-screen bg-gray-800 text-white transition-all duration-300`}
      >
        <div className="flex justify-between items-center hover:bg-gray-700 rounded">
          {isSidebarOpen && (
            <h1 className="text-xl font-bold">Tutor Platter Crawler</h1>
          )}
          <button
            onClick={() => setSidebarOpen(!isSidebarOpen)}
            className="p-2"
          >
            <Menu size={20} />
          </button>
        </div>
        <nav className="mt-16">
          {navigationItems.map((item, index) => (
            <Link key={index} to={item.href} className="flex mb-4 hover:bg-gray-700 rounded">
              <span className="mr-3">{item.icon}</span>
              {isSidebarOpen && <span>{item.text}</span>}
            </Link>
          ))}
        </nav>

        <button onClick={handleLogout}>Logout</button>

      </aside>
  );
};

export default Sidebar;
